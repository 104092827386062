// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// breadcrumb divider
// $breadcrumb-divider: quote(">");

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #1DAB44;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// CAN-TZ Colors
$black: #000000;
$accent: #EB6402;
$ghost: #E5E5E5;
$ghost2: #D9E0E9;
$shout: #FFB800;

// Rainbows
$rainbow1: #009588;
$rainbow2: #4CAE50;
$rainbow3: #8BC34A;
$rainbow4: #CDDC39;
$rainbow5: #FFEB3B;
$rainbow6: #FFC108;
$rainbow7: #EB1461;

