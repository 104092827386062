// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~aos/dist/aos.css';
@import '~sweetalert2/src/sweetalert2';
@import 'chatbot.css';

@keyframes slightlyUp {
    from { top: 0; } to { top: -1em;}
}
@keyframes slightlyDown {
    from { top: -1em; } to { top: 0;}
}


/*
            COLORS:

            blue: #274F9D;
            red: #D43F29;
            blue_opac: rgba(38, 78, 157, 0.15);
            light: rgba(229, 229, 229, 0.3);
            logo-background: rgba(196, 196, 196, 1);


            GRADIENTS

            linear-gradient(90deg, #264E9D 0%, #D33D29 90.51%);
         */
body {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
}
body::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    //border-radius: 10px;
    background-color: #CCCCCC;
}

body::-webkit-scrollbar
{
    width: 6px;
    background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
    //border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #274F9D;
}

.boxed {
    max-width: 1200px !important;
    margin: auto;
}

.carousel-indicators {
    bottom: 8rem !important;
}

.blue {
    background: #274F9D !important;
}

#info {
    // padding: 8px;
}
#info a {
    text-transform: uppercase !important;
    text-decoration: none;
    font-size: 12px;
    font-weight: normal;
    font-variant: small-caps;
    padding: 8px !important;
}
#info a:hover {
    background: #9b2e1e;
    color: #fff !important;
}

.bg-blue {
    background: #D43F29;
}

.bg-light {
    background: rgba(229, 229, 229, 0.3) !important;
}
.pr-sm-3 {
    padding-right: 3rem !important;
}
.m-80 {
    margin: 80px;
}

.my-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.mx-80 {
    margin-left: 80px;
    margin-right: 80px;
}

.mt-80 {
    margin-top: 80px;
}

.mb-80 {
    margin-bottom: 80px;
}
.my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.mb-8 {
    margin-bottom: 2rem;
}
.mt-8 {
    margin-top: 2rem;
}
.p-80 {
    padding: 80px;
}

.py-80 {
    padding-top: 80px;
    padding-bottom: 80px;
}

.px-90 {
    padding-left: 90px;
    padding-right: 90px;
}
.pt-80 {
    padding-top: 80px;
}
.pb-80 {
    padding-bottom: 80px;
}
// .navbar .navbar-nav .nav-item a:hover {
//     background-color: #ddd;
//     color: black;
// }
.navbar-brand {
    position: absolute;
    top: 0;
    // left: 6%;
    background: #fff;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 1) !important;
}

.banner {
    position: relative;
    overflow: hidden;
}

.banner>img {
    width: 100%;
}

.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.59) 0%, rgba(0,0,0,0.22) 24%, rgba(0,0,0,0.12) 100%);
}

#fr {
    position: sticky;
    top: 0;
    z-index: 100;
}

.card-img {
    object-fit: cover;
}

.banner>.container-fluid {
    position: absolute;
    left: 100px;
    right: 0;
    top: 50%;
    text-align: left;
    transform: translateY(-50%);
}

.dropdown-icon {
    display: inline-block;
    margin-left: 20px;
    vertical-align: top;
}

.home-slider-content .home-slider-intro {
    font-size: 14px;
    color: #fff;
}

.home-slider-content .home-slider-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
}

.home-slider-content .home-slider-subtitle {
    font-size: 30px;
    font-weight: 700;
    line-height: 45px;
    color: #fff;
}

.home-button {
    display: inline-block;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

.home-button a {
    white-space: nowrap;
    display: block;
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    padding: 16px 55px;
}

.gradient {
    position: relative;
    background-image: linear-gradient(
      to right,
      #264E9D 0%, #D33D29 90.51%
    );
    z-index: 1;
  }

.gradient::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to right,#D33D29 0%, #264E9D 90.51%
    );
    z-index: -1;
    transition: opacity 0.5s linear;
    opacity: 0;
  }
.gradient:hover::before {
    opacity: 1;
  }

.products {
    position: relative;
    margin: -10rem auto auto auto;
    max-width: 90%;
    width: 90%;
    box-shadow: 0 6px 15px 10px rgba(0, 0, 0, 0.08);
    background: #fff;
    border-radius: 8px;
    // padding: 20px 51px;
}

.product {
    padding: 0 51px;
}

.product:hover {
    background: red;
    cursor: pointer;
    background: rgba(0, 105, 255, 0.08) !important;
}

.products-title a {
    text-decoration: none;
    color: #000;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}

.products-description {
    font-size: 14px;
    font-weight: 300;
    line-height: 20px;
}

.products-card {
    border-radius: 8px;
    overflow: hidden;
}

.products-card-overlay {
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.31%, rgba(0, 0, 0, 0.7) 100%);
}

.product .card .arr {
    transition: .5s ease-out;
}

.product:hover .card .arr {
    transform: translateX(10px);
}

.text-gradient {
    // background: linear-gradient(90deg, #264E9D 0%, #D33D29 90.51%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // background-clip: text;
    color: #264E9D;
}

.apps-header-title {
    font-size: 16px;
    font-weight: 700;
}

.apps-title {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 0;
}

.apps-description {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    margin-bottom: 0;
}

.services {
    background: rgba(38, 78, 157, 0.15);
}

.service {
    // transition: 1s;
    overflow: hidden;
}

.service .service-icon-container .service-icon, .service .service-arrow {
    transition: all .2s ease-out;
}

.bg_slider {
    --initialTextColor: #272838;
    --slideTextColor: #fff;

    --initialBgColor: #fff;
    --slideBgColor: #264E9D 0%, #D33D29 90.51%;

    color: var(--initialTextColor);

    background-image: linear-gradient(90deg,
        var(--initialBgColor) 2%,
        var(--initialBgColor) 50%,
        var(--slideBgColor) 50%,
        var(--slideBgColor) 100%);
    background-size: 400%;
    transition: background-position .3s ease-in;
    transition-delay: 0.0s, 0.15s;
}

.service-active {
    --initialTextColor: #272838;
    --slideTextColor: #fff;

    --initialBgColor: #fff;
    --slideBgColor: #264E9D 0%, #D33D29 90.51%;

    color: var(--initialTextColor);

    background-image: linear-gradient(90deg,
        var(--initialBgColor) 2%,
        var(--initialBgColor) 50%,
        var(--slideBgColor) 50%,
        var(--slideBgColor) 100%);
    background-size: 400%;
    transition: background-position .3s ease-in;
    transition-delay: 0.0s, 0.15s;
}
 .service-active:hover, .service-active:focus, .service-active:active {
    color: var(--slideTextColor);
    cursor: pointer;
    background-position: -50% 100%;
 }

 .service:first-child:active {
    color: var(--slideTextColor);
    cursor: pointer;
    background-position: -50% 100%;
 }

.bg_slider:hover, .bg_slider:hover a {
    color: var(--slideTextColor) !important;
    cursor: pointer;
    background-position: -50% 100%;
}

.bg_slider .service-icon{
    width: 48px;
}
.bg_slider:hover .service-icon{
    transform: scale(1.7);
    max-width: 64px;
}

.bg_slider:hover .service-arrow{
    transform: translateX(60px);
    opacity: 0;
}

.services-title {
    font-size: 20px;
    font-weight: 700;
}

.services-description {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
}

.footer {
    background: #274F9D;
}

.footer ul li a, .footer ul li span, .footer a.nav-link {
    font-size: 13px;
    color: #fff;
    opacity: 0.7;
}

.footer-section h5 {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    color: #fff;
    text-transform: uppercase;
}

.footer .nav-link {
    color: #fff;
}

.footer-bottom {
    padding: 8px 0;
}

.footer-bottom p{
    margin-bottom: 0;
    padding: 0 12px;
    border-right: 1px solid #333;
    color: #fff;
    opacity: 0.7;
}
.footer-bottom p a{
    white-space: nowrap;
    color: #fff;
    opacity: 0.7;
}

.typed-cursor {
    color: #fff !important;
    font-size: 32px;
}

hr {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    display: none;
}
.megamenu a {
    text-decoration: none;
    font-weight: 300;
    color: #13264b;
    padding: 0.8rem 0;
    border-radius: 5px;
}
.ibank {
    background: #D43F29;
}
.ibank:hover {
    background: #a93221;
}
.megamenu a .nav-link-icon {
    display: none;
}



// default link hover state

.link-background__hover{
    display: flex;
    justify-content: space-between;
    padding: 0.8rem !important;
    white-space: nowrap;
    border-radius: 5px;
    color: #13264b;
    text-decoration: none;
    font-weight: 300;
}
.link-background__hover.active{
    color: #1633FF!important;
    background: rgba(0, 105, 255, 0.08) !important;
}
.link-background__hover.active .nav-link-icon{
    display: inline-block;
}
.link-background__hover .nav-link-icon{
    display: none;
}
.link-background__hover:hover {
    color: #1633FF!important;
    background: rgba(0, 105, 255, 0.08) !important;
}
.link-background__hover:hover .nav-link-icon{
    display: inline-block;
}


.megamenu a:hover {
    color: #1633FF!important;
    background: #ffffff !important;
}
.megamenu a:hover .nav-link-icon{
    display: inline-block;
}
.megamenu-title {
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    color: #444;
    margin-bottom: 0.8rem;
    padding-left: calc(95% - 89%);
    padding-right: calc(95% - 89%);
}

.megamenu .page-menu a{
    padding-left: calc(95% - 89%);
    padding-right: calc(95% - 89%);
}

.megamenu-subtitle {
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: 700;
    color: #444;
    display: none;
}

.megamenu-nav-intro .marker {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
}

.megamenu-nav-intro h2 {
    font-size: 3rem;
    font-weight: 700;
    line-height: 1.3;
}

.megamenu-nav-intro p {
    font-size: 1rem;
    line-height: 1.3;
}

#navigation-menu {
    transition: .2s ease-in;
    border-bottom: 1px solid rgba(229, 229, 229, 0.3);
}
// #navigation-menu {
//     background: #fff;
// }
#navigation-menu:hover {
    background: #274F9D;
}

.navbar-brand-el {
    transition: all .2s ease-out;
    transform: scale(1,1);
}

#navigation-menu:hover .has-megamenu>a {
    color: #fff !important;
}

#navigation-menu .has-megamenu:hover {
    background: #204182;
}
#info #infoNavbar{
    display: flex !important;
    flex-flow: row;
}

#info #infoNavbar {
    display: flex !important;
    flex-flow: row;
}

#info #infoNavbar > .navbar-nav{
    flex-direction: row !important;
}

.megamenu  [class*="col-"]:first-child{
    padding-left: 140px !important;
}

.megamenu  [class*="col-"]{
    padding: 35px !important;
    height: 490px;
}


// breadcrumb styles
.breadcrumb {
    background: transparent !important;
    margin-bottom: 0;
}
.breadcrumb .breadcrumb-item:not(.active) {
    color: #1633FF !important;
}

// navigation search button
.search-box {
    width: fit-content;
    height: fit-content;
    position: relative;
}
.input-search {
    height: 35px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 14px;
    outline: none;
    border-radius: 25px;
    transition: width .5s ease-in-out;
    background-color: transparent;
    padding-right: 40px;
    color:#fff;
}
.input-search::placeholder {
    color:rgba(255,255,255,1);
    font-size: 14px;
    font-weight: 100;
}
.btn-search {
    width: 50px;
    height: 25px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    color:#ffffff ;
    background-color:transparent;
    pointer-events: painted;
}
.btn-search:focus ~ .input-search {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:2px solid rgba(255,255,255,.5);
    transition: width 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.search-text {
    font-size: 12px;
}
.btn-search:focus .search-text {
   display: none;
}
.input-search:focus {
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:2px solid rgba(255,255,255,.5);
    transition: width 500ms cubic-bezier(0, 0.110, 0.35, 2);
}


// pages stylesheet
h2.page-header{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 0;
}

/* desktop view */
@media all and (min-width: 992px) {
    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    .navbar .has-megamenu {
        position: static !important;
    }

    .navbar .dropdown-menu {
        min-height: 20rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .navbar .megamenu {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        background: #fff;
    }

    .navbar .megamenu .nav-link {
        color: #274F9D !important;
    }

    .navbar .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .navbar .nav-item .dropdown-menu {
        display: none;
    }

    .navbar .nav-item:hover .nav-link {
        color: #fff;
    }

    .navbar .nav-item:hover .dropdown-menu {
        display: block;
    }

    .navbar .navbar-brand-img {
        transition: all .3s;
        width: auto;
    }

    .navbar .nav-item .dropdown-menu {
        margin-top: 0;
    }

    #navigation-menu:hover .navbar-brand-el{
        top: -26px;
        transform: scale(0.6, 0.6);
    }
}

@media all and (min-width: 481px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}


@media all and (max-width: 480px) {
    .desktop {
        display: none !important;
    }
    .navigation-container {
        max-width: 100% !important;
        // padding: 0 1.5rem !important;
    }
    .mobile-view {
        position: relative !important;
        background: #274F9D;
    }
    .mobile-view .navbar-brand {
        z-index: 100
    }
    .mobile-view-toggler {
       border-color: transparent !important;
       margin-right: 1rem !important;
    }
    #info #infoNavbar{
        display: flex !important;
    }
    #info #infoNavbar > .navbar-nav{
        flex-direction: row !important;
    }
    #info .nav-top-text {
        display: none !important;
    }
    #navigationNavbar {
        border-top: 1px solid rgb(255, 255, 255);
        padding-top: 3rem !important;
        margin-top: 0.6rem;
    }
    #navigationNavbar ul li a.parent-menu-link{
        padding: 0.5rem 1.5rem !important;
    }
    #navigationNavbar ul li .megamenu{
        padding: 0.5rem 0.95rem !important;
    }
    #navigationNavbar ul li .megamenu .megamenu-title{
        font-size: 14px;
        margin-bottom: .5rem;
    }
    #navigationNavbar ul li .megamenu > .container {
        display: block;
    }
    #navigationNavbar ul li .megamenu .container > .row {
        margin-bottom: 1rem !important;
    }
    #navigationNavbar ul li .megamenu > .container, #navigationNavbar ul li .megamenu .container > .row{
        max-width: auto !important;
        width: auto !important;
    }
    #navigationNavbar ul li .megamenu .container > .row > [class*="col-"] {
        border-bottom: 1px solid #f6f6f6;
        padding-top: 1rem !important;
    }
    .megamenu  [class*="col-"]{
        max-width: 100% !important;
        width: 100% !important;
        padding: 0 !important;
        flex: 0 0 100% !important;
    }
    .megamenu  [class*="col-"]:first-child {
       padding-left: 0 !important;
    }
    .products {
        margin-top: 1rem;
        // padding: 20px;
    }
    .product {
        padding: 0 20px;
    }
    .mobile {
        display: block;
    }
    .mobile #menus .nav-link {
        font-size: 12px !important;
    }
    .page-view-body .page-view-body-content {
        padding: 0 12px !important;
    }
    .footer-bottom .d-flex{
        justify-content: space-between;
    }
    .footer-bottom p{
        display: block;
        margin-bottom: 5px;
        padding: 0;
        border-right: none;
        color: #fff;
        opacity: 0.7;
    }
    .footer-bottom p a{
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .products {
        margin: 0 auto !important;
    }
}

@media all and (max-width: 991.98px) {
    .navbar-brand-img {
        width: 5rem !important;
    }
}

#navigation-menu .show {
    //animation-name: 'fadeIn';
    //animation-duration: 2s;
    //animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/** GRID **/
@media (max-width: 767px) { // xs
    #nav-box {
        top: 0 !important;
        background-color: unset !important;
        div.nav {
            background-color: unset !important;
            div.navbar {
                padding: 0 !important
            }
        }
        ul.navbar-nav{
            background-color: rgba(255,255,255,0.8);
            z-index: 4;
            >li {
                height: 2em;
                line-height: 2em;
            }
        }

        div.main-nav {
            padding: .1em !important;
        }
    }

    .news {
        padding: 2rem !important;
    }

}
@media (min-width: 768px) and (max-width: 991px) { // sm

}

@media (min-width: 992px) and (max-width: 1199px) { // md

}
